import validatorCommon from '../../services/helpers/validator.common';
import i18n from '../../services/lang';
import http from '../../services/helpers/http';

const validators = {
	async validateObjectUrl(value, required, model) {
		let existEmail = await http.get('Object/CheckExistsUrl', { objectId: model.ObjectId, objectUrl: value });

		if (existEmail.data) {
			return [i18n.t('urlnamemustbeor')];
		}

		let res = validatorCommon.checkEmpty(value, required);
		if (res) return res;

		let regex = /[À-ž/\s]/;
		if (regex.test(value)) {
			return [i18n.t('amistake!')];
		}

		return null;
	},
};

export default validators;

<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		tooltipMsg="manualforobject3297"
		@saveForm="saveObject($event)"
		@closeDetail="redirectToTable()"
	>
		<template #header>
			{{ $t('settingsfor') }}
			<b-link target="_blank" :href="`${model.ObjectUrlLink}?tkn=${user.AutologCode}`">
				{{ model.ObjectNameSk }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.ObjectId }}</label>
		</template>

		<template #beforeForm>
			<remark
				:entityId="detailEntityId"
				:backmethod="redirectToTable"
				:entityTypeId="remarkEntityType"
				:remarkTypeId="null"
				:administratorList="administratorList"
			></remark>

			<email-send-dropdown
				:labelText="$t('e-mails:')"
				:buttonText="$t(`fornewyear'sev`)"
				:pesLogTypeId="pesLogTypeId"
				:offersList="offersList"
				:entityId="detailEntityId"
				:emailExist="model.EmailExists"
				:withTable="true"
			></email-send-dropdown>
		</template>

		<template #afterForm>
			<products-section
				ref="productsSection"
				:userId="model.UserId"
				:markCurrentPackage="true"
				:objectId="detailEntityId"
				:objectCountryId="model.ObjectCountryId"
			></products-section>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';

import serviceEnums from '@/services/service/enums.service';
import apiUser from '@/services/api/user.api';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';
import http from '../../services/helpers/http';

import { model, fields } from './object.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Object',
			routeParam: 'ObjectId',
			model,
			schema: {
				groups: fields,
			},
			user: auth.GetUser(),
			offersList: [],
			administratorList: [],
			remarkEntityType: enums.remarkEntityType.Object,
			pesLogTypeId: enums.sendLogsTypeEnum.LodgingObject,
		};
	},

	computed: {},

	async created() {
		const [emailSubjects, admins] = await Promise.all([this.getEmailSubjects(), this.getAdministrators()]);

		this.offersList = emailSubjects;
		this.administratorList = admins;
	},

	methods: {
		async loadResources() {
			this.updateSchemaFields({
				ObjectCondition: { values: serviceEnums.getEnumForVFG('objectCondition') },
				ObjectVipPlacingsDateValidStart: {
					visible: (model) => model && model.ObjectVipPlacings > 0,
				},
				ObjectVipPlacingsDateValidEnd: {
					visible: (model) => model && model.ObjectVipPlacings > 0,
				},
			});
		},

		transformDtoToModel(dtoModel) {
			this.updateSchemaFields({
				ObjectIsVerified: {
					user: {
						ContactPersonName: dtoModel.VerifiedByUser ? dtoModel.VerifiedByUser.Text : '',
						ContactPersonSurname: '',
					},
					date: dtoModel.VerifiedDate,
				},
			});

			dtoModel.DateRange = {
				startDate: dtoModel.ObjectVipPlacingsDateValidStart,
				endDate: dtoModel.ObjectVipPlacingsDateValidEnd,
			};

			// TODO nenasiel som, kde sa plni tento field, zo servera vyzera ze nejde, nizsie je hack, aby nepadala stranka
			dtoModel.InvoiceAddressId = dtoModel.InvoiceAddressId || 0;

			return dtoModel;
		},

		async getEmailSubjects() {
			const emailIds = '7,50,51,59,60,63,30,29,28,1622';
			return servicePortalEmail.getEmailSubjectsByIds(emailIds).then((res) => res.data);
		},

		async getAdministrators() {
			const admins = await apiUser.getAdministratorList().then((res) => res.data);
			admins.unshift({ id: null, name: this.$i18n.t('unassigned') });
			return admins;
		},

		async loadModel(id) {
			const value = id || this.detailEntityId;
			const resp = await http.get(this.controllerName + '/GetDetailById', { id: value });
			const model = this.transformDtoToModel(resp.data);
			this.updateFormModel(model, true);
		},

		async saveObject({ closeOnSuccess }) {
			const isValid = await this.$refs[this.detailPageRefName].validate();
			if (!isValid) {
				return;
			}

			// start loading must be after the validation, otherwise the validation will not work
			this.startLoading();

			const dtoModel = this.transformModelToDto(this.model);
			const res = await http.put(this.controllerName + '/UpdateDetail', dtoModel)
				.then((res) => res.data)
				.finally(() => this.stopLoading());

			if (closeOnSuccess) {
				return this.redirectToTable();
			}
			
			if (this.detailEntityId === 0) {
				return this.redirectToDetail(res);
			}
			
			return await this.loadModel();
		},
	},
};
</script>

<style lang="scss">
.subcribe-history,
.subcribe-history-visitor {
	margin-bottom: 0px;
}
.vue-form-generator .inline-input-group {
	.form-group.error {
		position: relative;
		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}

	@include ig-first-item(25%, 77%, 30%);
	@include ig-second-item(70%, 10px);
}
</style>

import http from '../../services/helpers/http';

const api = {
	getEmailSubjectsByIds(emailIds) {
		return http.get('PortalEmail/GetEmailSubjectsByIds', {
			emailIds: emailIds,
		});
	},
};

export default api;

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: false,
          tooltipMsg: "manualforobject3297",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveObject($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n\t\t" + _vm._s(_vm.$t("settingsfor")) + "\n\t\t"),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          _vm.model.ObjectUrlLink +
                          "?tkn=" +
                          _vm.user.AutologCode,
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" + _vm._s(_vm.model.ObjectNameSk) + "\n\t\t"
                      ),
                    ]
                  ),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.ObjectId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "beforeForm",
              fn: function () {
                return [
                  _c("remark", {
                    attrs: {
                      entityId: _vm.detailEntityId,
                      backmethod: _vm.redirectToTable,
                      entityTypeId: _vm.remarkEntityType,
                      remarkTypeId: null,
                      administratorList: _vm.administratorList,
                    },
                  }),
                  _c("email-send-dropdown", {
                    attrs: {
                      labelText: _vm.$t("e-mails:"),
                      buttonText: _vm.$t("fornewyear'sev"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      offersList: _vm.offersList,
                      entityId: _vm.detailEntityId,
                      emailExist: _vm.model.EmailExists,
                      withTable: true,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _c("products-section", {
                    ref: "productsSection",
                    attrs: {
                      userId: _vm.model.UserId,
                      markCurrentPackage: true,
                      objectId: _vm.detailEntityId,
                      objectCountryId: _vm.model.ObjectCountryId,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3675773446
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }